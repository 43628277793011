<template>
  <v-dialog v-model="open" max-width="400">
    <v-card>
      <v-card-title class="headline">{{ heading }}</v-card-title>
      <v-card-text>{{message}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onClickYes">Yes</v-btn>
        <v-btn color @click="onClickCancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["heading", "message", "onYes", "onCancel"],
  data() {
    return {
      open: false
    };
  },
  methods: {
    openDialog() {
      this.open = true;
    },
    onClickYes() {
      this.open = false;
      this.$emit("onYes");
    },
    onClickCancel() {
      this.open = false;
      this.$emit("onCancel");
    }
  }
};
</script>

